<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<p class="nav__item nav__item--left">
				<span class="nav__btn">
					<i class="icon icon-location"></i>
				</span>
				<span class="nav__text">
					{{location}}
				</span>
			</p>
			<a class="nav__btn nav__btn--map" v-if="hotels.length > 0" @click="handleOpenMapRooms">
				<i class="icon icon-map"></i>
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub around">
			<!-- category -->
			<section class="listsearch">
				<div class="listsearch__wrap">
					<div class="listsearch__inner">
						<router-link to="/layers/date" custom v-slot="{ navigate }" >
							<div class="listsearch__box" @click="navigate">
								<span class="listsearch__icon">
									<i class="icon icon-date"></i>
								</span>
								<input v-if="dateRangeText !== null" type="text" class="listsearch__ipt" :value="dateRangeText" readonly> 
							</div>
						</router-link>
					</div>
					<div class="listsearch__inner">
						<router-link to="/layers/member" custom v-slot="{ navigate }" >
								<div class="listsearch__box" @click="navigate">
									<span class="listsearch__icon">
										<i class="icon icon-member"></i>
									</span>
									<input v-if="selectedMember !== null" type="text" class="listsearch__ipt" :value="`성인 ${selectedMember.adultCount}명, 아동 ${selectedMember.childCount}명`" readonly>
								</div>
						</router-link>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="box">
					<div class="menus">
						<div class="menus__wrap">
							<!-- [D] 활성화된 지역에 active 클래스 추가 -->
							<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
							<div class="menus__box">
								<div class="menus__text" :class="{active: hotelType === 'MO'}" @click="setHotelType('MO')">{{t('10021')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'HO'}" @click="setHotelType('HO')">{{t('10022')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'PS'}" @click="setHotelType('PS')">{{t('10023')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'RT'}" @click="setHotelType('RT')">{{t('10024')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'CP'}" @click="setHotelType('CP')">{{t('10025')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'GH'}" @click="setHotelType('GH')">{{t('10029')}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="listbox">
					<div class="box">
						<div class="list__meta">
							<div class="list__check">
								<div class="checkbox">
									<label>
										<input type="checkbox" class="chk blind">
										<span class="checkbox__bg"></span>
										{{t('10008')}}
									</label>
								</div>
							</div>
							<div class="list__option">
								<button type="button" @click="handleOpenSorting">
									<i class="icon icon-align">
										{{t('10009')}}
									</i>
								</button>
								<button type="button" @click="handleOpenFilter">
									<i class="icon icon-filter">
										{{t('10017')}}
									</i>
								</button>
							</div>
						</div>
					</div>
					<!-- [REST] 숙소 리스트 -->
					<rooms-thumbnail-list v-if="hotelType === 'HO' && hotels.length > 0" :list="hotels.map(item => {
						return {
							...item,
							mHOTEL_LOCTEXT: `${item.DISTANCE_KM}km/${item.mHOTEL_LOCTEXT}`,
							SALES_DAYUSE: item.SALESAMT_DAYUSE,
							SALES_AMT: item.SALESAMT_NIGHT,
						}	
					})" :sortMode="sortMode"/>
					<rooms-list v-else-if="hotels.length > 0" :list="hotels.map(item => {
						return {
							...item,
							mHOTEL_LOCTEXT: `${item.DISTANCE_KM}km/${item.mHOTEL_LOCTEXT}`,
							SALES_DAYUSE: item.SALESAMT_DAYUSE,
							SALES_AMT: item.SALESAMT_NIGHT,
						}	
					})" :sortMode="sortMode"/>
					<!-- 숙소 없을 시 사용 -->
					<div class="row" v-else>
						<div class="centered" style="height:calc(100vh - 320px)">
							<div class="centered__wrap">
								<div class="textbox">
									<div class="textbox__icon">
									<img src="@/assets/images/img_warning.png" alt="숙소 없음">
									</div>
									<h3 class="textbox__title">
										{{t('10026')}}
									</h3>
									<p class="textbox__description" v-html="t('10027')">
									</p>
									<div class="textbox__button">
												<router-link to="/rooms/list">
										<button class="btn btn-primary purple" type="button">{{t('10028')}}</button>
												</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
	<RoomSorting v-if="openSorting" :handleClose="handleCloseSorting" :mode="sortMode" :changeMode="changeSortMode"/>
	<RoomFilter v-if="openFilter" :handleClose="handleCloseFilter" :handleSubmit="handleSubmitFilter" />
	<MapRooms v-if="openMapRooms" :handleClose="handleCloseMapRooms" :list="hotels.map(item => {
						return {
							...item,
							mHOTEL_LOCTEXT: `${item.DISTANCE_KM}km/${item.mHOTEL_LOCTEXT}`,
							SALES_DAYUSE: item.SALESAMT_DAYUSE,
							SALES_AMT: item.SALESAMT_NIGHT,
						}	
					})" :mylocation="true" />
					
  <TheTop/>
  <TheNavigation></TheNavigation>
</template>

<script>
import { computed, onMounted, ref, reactive } from 'vue';
import { useStore } from 'vuex'
import RoomsList from '@/components/RoomsList';
import RoomsThumbnailList from '@/components/RoomsThumbnailList';
import dayjs from 'dayjs';
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n'
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	setup() {
		const store = useStore();

		const userData = computed(() => store.state.userData);

		const hotelType = ref("MO");
		const cp_yn = ref(false);
		const location = ref("");
		const openSorting = ref(false);
		const openFilter = ref(false);
		const openMapRooms = ref(false);
		const sortMode = ref(0);
		const watchCall = ref(false);
	    const { t }= useI18n() //번역필수 모듈
		
		let filterData = reactive({convs:undefined,price:[undefined, undefined]});

		onMounted(() => {
			try {
				store.dispatch("app/requestLocation");
			} catch(e) {
				navigator.geolocation.getCurrentPosition(
					function(position) {
					var posi_lat = position.coords.latitude;
					var posi_longi = position.coords.longitude;
					store.commit("app/SET_LOCATION", {
						location: {
							lat: posi_lat,
							lng: posi_longi
						}
					});
					}, 
				);
			}

			if(store.state.mhm01.selectedDateRange === null) {
				store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
			}
			if(store.state.mhm01.selectedMember === null) {
				store.commit('mhm01/SET_MEMBER', {member: {adultCount: 2, childCount: 0}});
			}
		});

		store.watch(() => [
			watchCall.value,
			store.state.app.location.lat, 
			store.state.app.location.lng,
			store.state.mhm01.selectedDateRange,
			store.state.mhm01.selectedMember,
			hotelType.value,
			cp_yn.value,
			filterData
		/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
		], ([_ , latitude, longitude, selectedDateRange, selectedMember, hotel_type, cp_yn, filterData]) => {
			//console.log(selectedDateRange, selectedMember, hotel_type, cp_yn, filterData);
			
			if(selectedDateRange !== null && selectedMember !== null) {
				store.dispatch("mrm01/fetchMrm01051", {
					proc_cd: "01",
					sdate: dayjs(selectedDateRange.start).format("YYYYMMDD"),
					edate: dayjs(selectedDateRange.end).format("YYYYMMDD"),
					// latitude, longitude 주석처리 안하면 값 안옴
					latitude,
					longitude,
					hotel_types: hotel_type,
					adult_cnt: selectedMember.adultCount,
					child_cnt: selectedMember.childCount,
					room_cnt: 1,
					cp_yn: cp_yn ? "Y" : undefined,
					mem_id: userData.value.mem_token,
					hotel_convs: filterData.convs === undefined ? undefined : filterData.convs.join(","),
					samt: filterData.price[0] !== undefined ? Number(filterData.price[0]) * 10000 : undefined,
					eamt: filterData.price[1] !== undefined ? filterData.price[1] > 50 ? undefined : Number(filterData.price[1]) * 10000 : undefined,
				})
			}
		}, {immediate: true});
		store.watch(() => [store.state.app.location.lat, store.state.app.location.lng]
		/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
		, ([latitude, longitude]) => {
			const geocoder = new window.kakao.maps.services.Geocoder()
			geocoder.coord2RegionCode(longitude, latitude, 
				(result, status) => {
					if(status === "OK") {
						location.value = result[0].region_1depth_name + " " + result[0].region_2depth_name
					}
				}
			);
			// store.dispatch("mrm01/fetchMrm0105", {
			// 	hotelType,
			// 	latitude,
			// 	longitude
			// });
		});

		return {
			sortMode,
			t,  //번역필수 모듈
		  i18n,
			changeSortMode:(m) => {
				sortMode.value = m;
			},
			hotels: computed(() => store.state.mrm01.mrm01051),
			dateRangeText: computed(() =>
				store.state.mhm01.selectedDateRange !== null
				? dayjs(store.state.mhm01.selectedDateRange.start).format(
					"MM.DD(dd)"
					) +
					"~" +
					dayjs(store.state.mhm01.selectedDateRange.end).format("MM.DD(dd)")
				: null
			),
			selectedMember: computed(() => store.state.mhm01.selectedMember),
			location,
			setHotelType: (type) => {
				hotelType.value = type;
			},
			hotelType,
			openSorting,
			handleOpenSorting: () => {
				openSorting.value = true;
			},
			handleCloseSorting: () => {
				openSorting.value = false;
			},
			openFilter,
			handleOpenFilter: () => {
				openFilter.value = true;
			},
			handleCloseFilter: () => {
				openFilter.value = false;
			},
			openMapRooms,
			handleOpenMapRooms: () => {
				openMapRooms.value = true;
			},
			handleCloseMapRooms: () => {
				openMapRooms.value = false;
			},
			handleSubmitFilter: (convs, price) => {
				openFilter.value = false;
				filterData = reactive({
					convs,
					price,
				});
				watchCall.value = !watchCall.value;
			},
		}
	},
	components: {
		RoomsList,
		RoomsThumbnailList,
	}
}
</script>